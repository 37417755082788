var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-status"},[(!_vm.isWeixin || _vm.orderInfo.paid || _vm.orderInfo.pay_type == 'offline')?_c('div',{staticClass:"iconfont icon-duihao2 bg-color-red"}):_c('div',{staticClass:"iconfont icon-iconfontguanbi fail"}),(
      (!_vm.isWeixin && _vm.orderInfo.pay_type == 'weixin') ||
        _vm.orderInfo.pay_type == 'offline'
    )?_c('div',{staticClass:"status"},[_vm._v(" 订单创建成功 ")]):(_vm.orderInfo.paid)?_c('div',{staticClass:"status"},[_vm._v("订单支付成功")]):_c('div',{staticClass:"status"},[_vm._v("订单支付失败")]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"item acea-row row-between-wrapper"},[_c('div',[_vm._v("订单编号")]),_c('div',{staticClass:"itemCom",domProps:{"textContent":_vm._s(_vm.orderInfo.order_id)}})]),_c('div',{staticClass:"item acea-row row-between-wrapper"},[_c('div',[_vm._v("下单时间")]),_c('div',{staticClass:"itemCom",domProps:{"textContent":_vm._s(_vm.orderInfo.add_time)}})]),_c('div',{staticClass:"item acea-row row-between-wrapper"},[_c('div',[_vm._v("支付方式")]),(_vm.orderInfo.pay_type == 'weixin' && _vm.orderInfo.is_channel == 2)?_c('div',{staticClass:"itemCom"},[_vm._v(" H5微信支付 ")]):_vm._e(),(_vm.orderInfo.pay_type == 'weixin' && _vm.orderInfo.is_channel == 0)?_c('div',{staticClass:"itemCom"},[_vm._v(" 微信支付 ")]):_vm._e(),(_vm.orderInfo.pay_type == 'yue')?_c('div',{staticClass:"itemCom"},[_vm._v("余额支付")]):_vm._e(),(_vm.orderInfo.pay_type == 'offline')?_c('div',{staticClass:"itemCom"},[_vm._v(" 线下支付 ")]):_vm._e()]),_c('div',{staticClass:"item acea-row row-between-wrapper"},[_c('div',[_vm._v("支付金额")]),_c('div',{staticClass:"itemCom",domProps:{"textContent":_vm._s(_vm.orderInfo.pay_price)}})]),(
        _vm.orderInfo.paid == 0 &&
          _vm.orderInfo.pay_type != 'offline' &&
          _vm.isWeixin &&
          _vm.msgContent
      )?_c('div',{staticClass:"item acea-row row-between-wrapper"},[_c('div',[_vm._v("失败原因")]),_c('div',{staticClass:"itemCom",domProps:{"textContent":_vm._s(_vm.msgContent)}})]):_vm._e()]),(_vm.orderInfo.pay_type == 'weixin' && _vm.orderInfo.is_channel == 2)?_c('div',[_c('div',{staticClass:"returnBnt bg-color-red",on:{"click":function($event){return _vm.goPages()}}},[_vm._v("查看支付结果")]),_c('div',{staticClass:"returnBnt cart-color",on:{"click":function($event){return _vm.goPages()}}},[_vm._v(" 支付失败重新支付 ")])]):_c('div',[_c('div',{staticClass:"returnBnt bg-color-red",on:{"click":function($event){return _vm.goPages()}}},[_vm._v("查看订单")]),_c('div',{staticClass:"returnBnt cart-color",on:{"click":function($event){return _vm.goPages('/')}}},[_vm._v("返回首页")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }